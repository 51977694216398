import { Component, OnInit } from '@angular/core';

import { Router, RouterOutlet } from '@angular/router';
import { HttpLoaderModule } from '@shared/components/http-loader/http-loader.module';
import { AuthHelperService } from '@core/auth-helper.service';
import { ToasterComponent } from '@shared/toast/components/toaster/toaster.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { ToastComponent } from '@shared/toast/components/toast/toast.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HttpLoaderModule, ToastComponent,ToasterComponent, LoaderComponent,],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'tradesentinel-admin';

  constructor(
    private authHelperService:AuthHelperService,
    private router:Router
    ) {}
  ngOnInit(): void {
    if(!this.authHelperService.isUserLogged()){
      this.router.navigate(['sign-in'])
    }
  }
}
