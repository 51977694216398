import { Injectable } from '@angular/core';
import { AuthHelperService } from './auth-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(
    private authHelperService:AuthHelperService) {}

    isAuthorized(allowedRoles: string[]): boolean {
      if (allowedRoles == null || allowedRoles.length === 0) {
        return true;
      }
  
      const token = this.authHelperService.getAccessToken();
  
      const decodeToken = this.decodeJwt(token);
  
      if (!decodeToken) {
        console.log('Invalid token');
        return false;
      }
  
      return allowedRoles.includes(decodeToken.role)?true:false;
    }

    decodeJwt(jwt:string) {
        const base64Url = jwt.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );
      
        return JSON.parse(jsonPayload);
      }
}