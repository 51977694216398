import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpLoaderComponent } from './http-loader.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpLoaderInterceptor } from './http-loader.interceptor';



@NgModule({
  declarations: [
    HttpLoaderComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers:[{ provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true }],
  exports:[HttpLoaderComponent]
})
export class HttpLoaderModule { }
