import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Role } from './roles';
const accessTokenKey = 'accessToken';
const refreshTokenKey = 'refreshToken';
const userKey = 'user';
@Injectable({
  providedIn: 'root',
})
export class AuthHelperService {
  isUserLogged$: Subject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
    this.isUserLogged$.next(!!localStorage.getItem(accessTokenKey));
  }

  storePreference(
    accessToken: string,
    refreshToken: string,
    user?: {
      username: string;
      name: string;
      roleId:string;
    }
  ): void {
    localStorage.setItem(accessTokenKey, accessToken);
    localStorage.setItem(refreshTokenKey, refreshToken);
    localStorage.setItem(userKey, JSON.stringify(user));
    this.isUserLogged$.next(true);
  }

  isUserLogged(): boolean {
    return localStorage.getItem(accessTokenKey) ? true : false;
  }

  getAccessToken(): string {
    return localStorage.getItem(accessTokenKey) ?? '';
  }

  getRefreshToken(): string {
    return localStorage.getItem(refreshTokenKey) ?? '';
  }

  getUserDetail() {
    return JSON.parse(localStorage.getItem(userKey)) ?? {};
  }

  getRole() {
    const role= JSON.parse(localStorage.getItem(userKey)) ?? {};
    return role.roleId
  }

  isAdmin() {
    const role= JSON.parse(localStorage.getItem(userKey)) ?? {};
    return role.roleId==Role.Admin;
  }

  isModerator() {
    const role= JSON.parse(localStorage.getItem(userKey)) ?? {};
    return role.roleId==Role.Moderator;
  }

  isUser() {
    const role= JSON.parse(localStorage.getItem(userKey)) ?? {};
    return role.roleId==Role.User;
  }

  removePreference(): void {
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);
    this.isUserLogged$.next(false);
  }

}