import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
    providedIn: 'root',
  })
  export class PendingChangesGuard {
  canDeactivate(component: any): boolean | Observable<boolean> {
    return component.form.pristine? true : confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
  }
}