import { Routes } from '@angular/router';
import { AuthorizationGuard } from '@core/authorization.guard';
import { Role } from '@core/roles';
import { PendingChangesGuard } from '@shared/components/pending-changes.guard';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'sign-in',
        pathMatch: 'full'
    },
    {
        path: 'sign-in',
        loadComponent: () =>
            import('./pages/user/sign-in/sign-in.component').then((x) => x.SignInComponent),
    },
    {
        path: '',
        loadComponent: () =>
            import('./pages/_containers/layout/layout.component').then((x) => x.LayoutComponent),
        children: [

            {
                path: 'content',
                loadComponent: () =>
                    import('./pages/content/content.component').then((x) => x.ContentComponent),
                children: [
                    {
                        path: 'subscribers',
                        loadComponent: () =>
                            import('./pages/content/subscriber-list/subscriber-list.component').then((x) => x.SubscriberListComponent),
                        canActivate: [AuthorizationGuard],
                        data: {
                            allowedRoles: `${Role.Admin},${Role.Moderator},${Role.User}`,
                        },
                        children: [
                            {
                                path: 'create',
                                loadComponent: () =>
                                    import('./pages/content/create-subscriber/create-subscriber.component').then((x) => x.CreateSubscriberComponent),
                                canActivate: [AuthorizationGuard],
                                data: {
                                    allowedRoles: `${Role.Admin},${Role.Moderator},${Role.User}`,
                                },
                            },
                        ]
                    },
                   
                    {
                        path: 'list',
                        loadComponent: () =>
                            import('./pages/content/content-list/content-list.component').then((x) => x.ContentListComponent),
                        canActivate: [AuthorizationGuard],
                        data: {
                            allowedRoles: `${Role.Admin},${Role.Moderator},${Role.User}`,
                        },
                        children: [
                            {
                                path: 'create',
                                loadComponent: () =>
                                    import('./pages/content/create-content/create-content.component').then((x) => x.CreateContentComponent),
                                canDeactivate: [PendingChangesGuard],
                                canActivate: [AuthorizationGuard],
                                data: {
                                    allowedRoles: `${Role.Admin},${Role.Moderator},${Role.User}`,
                                },
                            },
                            {
                                path: 'edit/:id',
                                loadComponent: () =>
                                    import('./pages/content/update-content/update-content.component').then((x) => x.UpdateContentComponent),
                                canDeactivate: [PendingChangesGuard],
                                canActivate: [AuthorizationGuard],
                                data: {
                                    allowedRoles: `${Role.Admin},${Role.Moderator},${Role.User}`,
                                },
                            },
                        ]
                    },
                    {
                        path: 'create',
                        loadComponent: () =>
                            import('./pages/content/create-content/create-content.component').then((x) => x.CreateContentComponent),
                        canDeactivate: [PendingChangesGuard],
                        canActivate: [AuthorizationGuard],
                        data: {
                            allowedRoles: `${Role.Admin},${Role.Moderator},${Role.User}`,
                        },
                    },
                    {
                        path: 'edit/:id',
                        loadComponent: () =>
                            import('./pages/content/update-content/update-content.component').then((x) => x.UpdateContentComponent),
                        canDeactivate: [PendingChangesGuard],
                        canActivate: [AuthorizationGuard],
                        data: {
                            allowedRoles: `${Role.Admin},${Role.Moderator},${Role.User}`,
                        },
                    },
                ]
            },
            {
                path: 'user',
                loadComponent: () =>
                    import('./pages/user/user.component').then((x) => x.UserComponent),
                children: [
                    {
                        path: 'list',
                        loadComponent: () =>
                            import('./pages/user/user-list/user-list.component').then((x) => x.UserListComponent),
                        canActivate: [AuthorizationGuard],
                        data: {
                            allowedRoles: `${Role.Admin},${Role.Moderator}`,
                        },
                        children: [
                            {
                                path: 'create',
                                loadComponent: () =>
                                    import('./pages/user/create-user/create-user.component').then((x) => x.CreateUserComponent),
                                canActivate: [AuthorizationGuard],
                                data: {
                                    allowedRoles: `${Role.Admin},${Role.Moderator}`,
                                },
                            },
                            {
                                path: 'edit/:id',
                                loadComponent: () =>
                                    import('./pages/user/update-user/update-user.component').then((x) => x.UpdateUserComponent),
                                canActivate: [AuthorizationGuard],
                                data: {
                                    allowedRoles: `${Role.Admin},${Role.Moderator}`,
                                },
                            },
                        ]
                    },

                ]
            }
        ]
    },
];
