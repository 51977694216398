import { ApplicationConfig } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withComponentInputBinding, withEnabledBlockingInitialNavigation, withInMemoryScrolling, withRouterConfig, withViewTransitions } from '@angular/router';
import { provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";

import { routes } from './app.routes';
import { tokenInterceptor } from '@core/token.interceptor';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);
  
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, inMemoryScrollingFeature, withComponentInputBinding(),
    withEnabledBlockingInitialNavigation(),
    withRouterConfig({
      onSameUrlNavigation: 'reload',
    }),
    withViewTransitions()),
  provideHttpClient(withFetch(),withInterceptors([tokenInterceptor])),
  ]
};

