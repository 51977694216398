import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ToastType} from "../../models/toast-type";
import {ToastCloseButtonComponent} from "./toast-close-button/toast-close-button.component";
import {ToastService} from "../../toast.service";

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [
    ToastCloseButtonComponent
  ],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent implements OnInit, OnDestroy {

  @Output() disposeEvent = new EventEmitter();

  @Input()
  type!: ToastType;

  @Input()
  title?: string;

  @Input()
  message!: string;

  @Input()
  duration?: number;

  protected readonly ToastType = ToastType;

  ngOnInit() {
    this.show();
  }

  show() {
    setTimeout(() => {
      this.hide();
    }, this.duration ?? ToastService.defaultDuration);
  }

  hide() {
    this.disposeEvent.emit();
  }

  ngOnDestroy(): void {
  }
}
