import { Component } from '@angular/core';
import { HttpLoaderService } from './http-loader.service';

@Component({
  selector: 'app-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss']
})
export class HttpLoaderComponent {
  show!: boolean;
  constructor(private _loaderService: HttpLoaderService) {}

  ngOnInit() {
    this._loaderService.loadState.subscribe(res => {
      this.show = res;
    });
  }
}
